import React, { useState } from "react";
import { Link } from "gatsby";

import lawyLogo from "../../assets/image/lawy.png"
import lawyLogoHover from "../../assets/image/lawy_hover.png"


const Logo = ({ className = "", ...rest }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}>
      <Link to="/" className={`${className}`} {...rest}>
        <img alt="Lawy the Elephant" className="light-version-logo" width="35px" src={isHover ? lawyLogoHover : lawyLogo}
          onClick={() => setIsHover(!isHover)} />
      </Link>
      <Link to="/">
        <h3 style={{ color: "#5584AC", marginLeft: "5px", fontSize: "24px" }}>lawy</h3>
      </Link>
    </div>
  );
};

export default Logo;
