export const menuItems = [

  {
    name: "company",
    label: "company",
    items: [
      // { name: "about", label: "About Us" },
      { name: "terms", label: "Terms & Conditions" },
      { name: "privacy", label: "Privacy Policy" },
      // {
      //   name: "utility",
      //   label: "Utility",
      //   items: [
      //     { name: "pricing", label: "Pricing & Plan" },
      //     { name: "contact", label: "Contact" },
      //     { name: "faq", label: "FAQ" },
      //     { name: "comingsoon", label: "Coming soon" },
      //     { name: "terms", label: "Terms & Conditions" },
      //     { name: "404", label: "404 Page" },
      //   ],
      // },
    ],
  },
  {
    name: "pricing",
    label: "Pricing & Plan",
  },
  {
    name: "contact",
    label: "Contact",
  },

];
